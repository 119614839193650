import axios from "axios"
import store from "@/store"
import router from "@/router"
import dayjs from "dayjs"
import i18n from "@/i18n"
import { useToast } from "vue-toastification"
const toast = useToast()

// CONSTANTS
const API_DATA_BASE =
    process.env.VUE_APP_PHP_SITE_BASE != ""
        ? process.env.VUE_APP_PHP_SITE_BASE
        : window.location.pathname
const API_DATA_URL =
    process.env.VUE_APP_PHP_SITE_URL != ""
        ? process.env.VUE_APP_PHP_SITE_URL
        : window.location.origin
const API_DATA_TIMEOUT = process.env.VUE_APP_API_DATA_TIMEOUT
const urlExceptionArray = ["version.php", "connexion.php"]
const init = function () {
    const instance = axios.create({
        baseURL: API_DATA_URL + API_DATA_BASE + "/",
        timeout: API_DATA_TIMEOUT,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
    })

    instance.interceptors.request.use(
        async (config) => {
            if (
                store.state.auth.jwt &&
                store.state.auth.expireAt &&
                !urlExceptionArray.includes(config.url)
            ) {
                let utc = require("dayjs/plugin/utc")
                dayjs.extend(utc)
                const expirationTime = dayjs.utc(store.state.auth.expireAt)
                const renewExpirationTime = dayjs.utc(
                    store.state.auth.renewExpireAt
                )
                const currentTime = dayjs.utc()

                if (expirationTime && expirationTime < currentTime) {
                    // If renew is expired : disconnection
                    if (
                        renewExpirationTime &&
                        renewExpirationTime < currentTime
                    ) {
                        toast.info(i18n.global.t("alerts.connection.expired"), {
                            icon: "fas fa-power-off",
                        })
                        router.push({
                            name: "Logout",
                            query: {
                                call: 0,
                            },
                        })
                        throw new axios.Cancel("Session timed out")

                        // Else : renewing token
                    } else {
                        // Renewing the token
                        router.push({
                            name: "Renew",
                        })

                        throw new axios.Cancel("Renew")
                    }
                }
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    instance.interceptors.response.use(
        function (response) {
            if (response && response.data === -2) {
                router.push({
                    name: "Logout",
                    query: {
                        call: 0,
                    },
                })
                return
            } else {
                return response
            }
        },
        function (error) {
            return Promise.reject(error)
        }
    )

    return instance
}

function setupAuthorizationHeader(config, jwt = store.state.auth.jwt) {
    const BEARER = `bearer ${jwt}`
    config.headers.Authorization = BEARER
}

// set CSRF header
function getHeaderCSRF() {
    const header_csrf = {
        headers: {
            'W-Kope': localStorage.getItem('csrf')
        }
    }
    return header_csrf
}

const httpIntratone = {
    get(resource, params = null) {
        return init()
            .get(resource, params)
            .catch((error) => {
                throw new Error(`${i18n.global.t("errors.contactSupport")}`)
            })
    },
    post(resource, postDatas, params = null) {
        params = getHeaderCSRF()
        return init()
            .post(resource, postDatas, params)
            .catch((error) => {
                throw new Error(`${i18n.global.t("errors.contactSupport")}`)
            })
    },
    update(resource, slug, params) {
        return init()
            .put(`${resource}/${slug}`, params)
            .catch((error) => {
                throw new Error(`${i18n.global.t("errors.contactSupport")}`)
            })
    },
    put(resource, params) {
        return init()
            .put(`${resource}`, params)
            .catch((error) => {
                throw new Error(`${i18n.global.t("errors.contactSupport")}`)
            })
    },
    delete(resource) {
        return init()
            .delete(resource)
            .catch((error) => {
                throw new Error(`${i18n.global.t("errors.contactSupport")}`)
            })
    },
}

export default httpIntratone
